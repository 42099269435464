import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import EventService, {
  EntriesResponse,
} from '../../../services/configur/EventService';
import { AppThunk } from '../../store';

interface EventsState {
  data: EntriesResponse;
  loading: boolean;
  error: string | null;
}

const initialState: EventsState = {
  data: {
    statusCode: 200,
    message: 'Initial Events',
    data: { entries: [], next: { hasMoreEntries: false, totalCount: 0 } },
  },
  loading: false,
  error: null,
};

const EventsSlice = createSlice({
  name: 'recentEvents',
  initialState,
  reducers: {
    // Fetch recent events
    fetchRecentEventsStart(state) {
      state.loading = true;
      state.error = null;
    },
    fetchRecentEventsSuccess(state, action: PayloadAction<EntriesResponse>) {
      state.data = action.payload;
      state.loading = false;
      state.error = null;
    },
    fetchRecentEventsFailure(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  fetchRecentEventsStart,
  fetchRecentEventsSuccess,
  fetchRecentEventsFailure,
} = EventsSlice.actions;

export default EventsSlice.reducer;

export const fetchRecentEvents =
  (token: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(fetchRecentEventsStart());

      const fetched = await EventService.getEvents(
        token,
        undefined,
        undefined,
        undefined,
        undefined,
        '-eventDate',
      );

      dispatch(fetchRecentEventsSuccess(fetched));
    } catch (error) {
      const err = error as string;
      dispatch(fetchRecentEventsFailure(err.toString()));
    }
  };
