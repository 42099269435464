import React, { FC, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { hideModal } from '../../store/modal';
import FadeIn from '../FadeIn/FadeIn';
import ApplyBoostersModal, {
  ApplyBoostersModalProps,
} from './boosters/ApplyBoostersModal';
import CascadeModal, { CascadeModalProps } from './cascade/CascadeModal';
import EnrichModal, { EnrichModalProps } from './enrich/EnrichModal';
import ErrorModal, { ErrorModalProps } from './error/ErrorModal';
import ExportModal, { ExportModalProps } from './export/ExportModal';
import FileSelectModal, {
  FileSelectModalProps,
} from './file-select/FileSelectModal';
import PabmModal, { PabmModalProps } from './pabm/PabmModal';
import * as SC from './styled';
import CascadeSuccessModal, {
  CascadeSuccessModalProps,
} from './success/CascadeSuccessModal';
import PabmSuccessModal, {
  PABMSuccessModalProps,
} from './success/PabmSuccessModal';
import SuccessModal, { SuccessModalProps } from './success/SuccessModal';

const ESC_KEY = 27;

export enum ModalTypes {
  ERROR = 'error',
  EXPORT = 'export',
  ENRICH = 'enrich',
  FILE_SELECT = 'file-select',
  APPLY_BOOSTERS = 'apply-boosters',
  SUCCESS = 'success',
  PABM = 'pabm',
  PABM_SUCCESS = 'pabm-success',
  CASCADE = 'cascade',
  CASCADE_SUCCESS = 'cascade-success',
}

export interface ModalProps {
  visible: boolean;
  modal?: ModalTypes;
  additionalProps?: Record<string, unknown>;
  style?: React.CSSProperties;
  fullScreen?: boolean;
  forceOpen?: boolean;
  scrollable?: boolean;
}

const getModal = (
  modal: ModalTypes,
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>,
  additionalProps?: Record<string, unknown>,
) => {
  let addProps;
  switch (modal) {
    case ModalTypes.ERROR:
      addProps = additionalProps as unknown as ErrorModalProps;
      return <ErrorModal setShowModal={setShowModal} {...addProps} />;
    case ModalTypes.EXPORT:
      addProps = additionalProps as unknown as ExportModalProps;
      return <ExportModal setShowModal={setShowModal} {...addProps} />;
    case ModalTypes.ENRICH:
      addProps = additionalProps as unknown as EnrichModalProps;
      return <EnrichModal setShowModal={setShowModal} {...addProps} />;
    case ModalTypes.FILE_SELECT:
      addProps = additionalProps as unknown as FileSelectModalProps;
      return <FileSelectModal setShowModal={setShowModal} {...addProps} />;
    case ModalTypes.APPLY_BOOSTERS:
      addProps = additionalProps as unknown as ApplyBoostersModalProps;
      return (
        <ApplyBoostersModal
          setShowModal={setShowModal}
          applyBoostersProps={addProps}
        />
      );
    case ModalTypes.SUCCESS:
      addProps = additionalProps as unknown as SuccessModalProps;
      return <SuccessModal setShowModal={setShowModal} {...addProps} />;
    case ModalTypes.PABM:
      addProps = additionalProps as unknown as PabmModalProps;
      return <PabmModal setShowModal={setShowModal} {...addProps} />;
    case ModalTypes.PABM_SUCCESS:
      addProps = additionalProps as unknown as PABMSuccessModalProps;
      return <PabmSuccessModal setShowModal={setShowModal} {...addProps} />;
    case ModalTypes.CASCADE:
      addProps = additionalProps as unknown as CascadeModalProps;
      return <CascadeModal setShowModal={setShowModal} {...addProps} />;
    case ModalTypes.CASCADE_SUCCESS:
      addProps = additionalProps as unknown as CascadeSuccessModalProps;
      return <CascadeSuccessModal setShowModal={setShowModal} {...addProps} />;
  }
};

const Modal: FC<ModalProps> = ({
  visible,
  modal,
  additionalProps,
  style,
  fullScreen,
  forceOpen,
  scrollable,
}) => {
  const dispatch = useDispatch();
  const clickRef = useRef<HTMLDivElement>(null);

  const [showModal, setShowModal] = useState<boolean>(false);

  useEffect(() => {
    if (!forceOpen) {
      const close = (e) => {
        // Use ESC to close modal
        if (e.keyCode === ESC_KEY) {
          dispatch(hideModal());
        }
      };

      // Click outside of the modal to also close it
      const handleClick = (e) => {
        if (clickRef.current && !clickRef.current?.contains(e.target)) {
          dispatch(hideModal());
        }
      };

      document.addEventListener('keydown', close);
      document.addEventListener('mousedown', handleClick);

      return () => {
        document.removeEventListener('keydown', close);
        document.removeEventListener('mousedown', handleClick);
      };
    }
  }, [dispatch, forceOpen]);

  if (!visible || !modal) {
    return null;
  }

  return (
    <FadeIn>
      <SC.Overlay>
        <SC.Wrapper
          ref={clickRef}
          showModal={showModal}
          style={style}
          fullScreen={fullScreen}
          scrollable={scrollable}
        >
          {getModal(modal, setShowModal, additionalProps)}
        </SC.Wrapper>
      </SC.Overlay>
    </FadeIn>
  );
};

export default Modal;
