import { Interfaces as BEInterfaces } from '@configur-tech/discover-core';
import { Enums, Interfaces } from '@configur-tech/discover-core-types';
import HttpHelper from '../../utils/http-helper/HttpHelper';

const endpoint = process.env['REACT_APP_CRUD_API'] || '';

interface PLResponseFormatting {
  data: { data: BEInterfaces.PLIncluded[] };
}

export default class EventsService {
  public static async getEvents(
    token: string,
    domain: string,
  ): Promise<Interfaces.PLEvent[]> {
    const ah = new HttpHelper(token);

    const url = `${endpoint}/${Enums.LambdaRoutes.READ_EVENTS_FROM_DOMAIN}/${domain}`;

    const response = await ah.get<PLResponseFormatting>(url, {});

    return response.data.data.filter(
      (event) => event.type === Enums.EventTypes.EVENT,
    ) as Interfaces.PLEvent[];
  }
}
