import { useEffect, useState } from 'react';

const THEME = 'theme';
const LIGHT_THEME = 'light';
const DARK_THEME = 'dark';

export const useDarkMode = () => {
  const [theme, setTheme] = useState<string>(LIGHT_THEME);

  const setMode = (mode) => {
    window.localStorage.setItem(THEME, mode);
    setTheme(mode);
  };

  const themeToggler = () => {
    theme === LIGHT_THEME ? setMode(DARK_THEME) : setMode(LIGHT_THEME);
  };

  useEffect(() => {
    const localTheme = window.localStorage.getItem(THEME);
    localTheme && setTheme(localTheme);
  }, []);

  return [theme, themeToggler];
};
