// Events
export const EVENT_ID_FIELD = 'd524aae6-301e-4cf9-9bf4-7eefaff5889a';
export const EVENT_ID_ALIAS = 'id';

export const EVENT_DOMAIN_FIELD = '22e91d7b-53ac-47de-9cec-951300f2abad'; // TODO: Fill in
export const EVENT_DOMAIN_ALIAS = 'domain';

export const EVENT_CATEGORY_FIELD = '8a7aa860-c824-419c-a1b3-a4831f185d65';
export const EVENT_CATEGORY_ALIAS = 'eventCategory';

export const EVENT_DATE_ORDERING = '-eventDate';

// Companies
export const COMPANY_DOMAIN_FIELD = '7902cea0-6e59-4211-8802-5501fdb91739';
export const COMPANY_DOMAIN_ALIAS = 'domain';

export const SEARCH_ID_FIELD_ALIAS = 'searchId';
export const SEARCH_ID_FIELD_ID = '49bb17dc-dad6-4cfc-8a49-5d891037de77';
