import { useAuth0 } from '@auth0/auth0-react';
import { faXmark } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC, useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { ThemeContext } from 'styled-components';
import * as xlsx from 'xlsx';
import { pabmTemplateFile } from '../../../consts/pabm';
import useToast from '../../../hooks/toast/useToast';
import useLoggedInUser from '../../../hooks/user/useLoggedInUser';
import { ExportFormat } from '../../../interfaces/ExportFormat';
import * as TC from '../../../main/theme';
import FileService, {
  GetFileUploadUrlResponse,
} from '../../../services/file/FileService';
import { hideModal, showModal } from '../../../store/modal';
import CSVReader from '../../CSVReader/CSVReader';
import { ModalTypes } from '../Modal';
import * as SC from './styled';

const EXTRA_LARGE = 'xl';
const XLSX_MIME_TYPE =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
const BUCKET_NAME = 'discover-pabm-upload';

export interface PabmModalProps {
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const PabmModal: FC<PabmModalProps> = ({ setShowModal }) => {
  const { loggedInUser } = useLoggedInUser();
  const { getAccessTokenSilently } = useAuth0();
  const { notifyToast } = useToast();

  const dispatch = useDispatch();
  const themeContext = useContext(ThemeContext);

  const [dataToExport, setDataToExport] = useState<
    ExportFormat[] | string[][]
  >();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [canExport, setCanExport] = useState<boolean>(false);
  const [acceptedCsvFile, setAcceptedCsvFile] = useState<File>();
  const [appendedCsvFile, setAppendedCsvFile] = useState<string>('');

  const [updatedWorkbookFile, setUpdatedWorkbookFile] = useState<File>();
  const [uploadingFile, setUploadingFile] = useState<boolean>(false);

  const closeModal = () => {
    dispatch(hideModal());
  };

  // Check if all export conditions met
  useEffect(() => {
    setCanExport(!(!dataToExport || isLoading));
  }, [dataToExport, isLoading]);

  // Set modal to display
  useEffect(() => {
    setShowModal(true);

    return () => setShowModal(false);
  }, [setShowModal]);

  useEffect(() => {
    if (!uploadingFile && updatedWorkbookFile && loggedInUser) {
      (async () => {
        setIsLoading(true);
        setUploadingFile(true);
        const token = await getAccessTokenSilently();

        // Get upload URL
        const uploadUrl = await FileService.getFileUploadUrl(
          token,
          acceptedCsvFile?.name,
          XLSX_MIME_TYPE,
          loggedInUser.auth0Id as string,
          BUCKET_NAME,
        );

        // Upload file to S3
        await FileService.uploadFileToS3(
          (uploadUrl as GetFileUploadUrlResponse).uploadURL,
          updatedWorkbookFile,
        );

        // Score companies
        notifyToast(
          `Document uploaded! You will receive an email once it has been processed.`,
          { type: toast.TYPE.INFO },
        );

        setIsLoading(false);
        dispatch(
          showModal({
            forceOpen: true,
            visible: true,
            modal: ModalTypes.PABM_SUCCESS,
          }),
        );
      })();
    }
  }, [
    acceptedCsvFile?.name,
    acceptedCsvFile?.type,
    dispatch,
    getAccessTokenSilently,
    loggedInUser,
    notifyToast,
    updatedWorkbookFile,
    uploadingFile,
  ]);

  // Send For Processing
  const sendForProcessing = async () => {
    setIsLoading(true);
    if (acceptedCsvFile && loggedInUser) {
      const reader = new FileReader();
      reader.readAsBinaryString(acceptedCsvFile);

      reader.onload = () => {
        const res = reader.result;
        const wb = xlsx.read(res, {
          type: 'binary',
        });

        // Get user object appended
        const userObjSheet = xlsx.utils.json_to_sheet([
          {
            firstName: loggedInUser.firstName,
            email: loggedInUser.email,
          },
        ]);

        xlsx.utils.book_append_sheet(wb, userObjSheet, 'User');

        setUpdatedWorkbookFile(
          xlsx.write(wb, {
            type: 'buffer',
            bookType: 'xlsx',
          }),
        );
      };
    }

    setIsLoading(false);
  };

  return (
    <SC.Wrapper>
      <SC.HeaderContainer>
        <SC.StretchedContainer>
          <TC.StyledParagraph>
            Personalised Copy /{' '}
            <b style={{ color: themeContext.colors.general.green }}>Generate</b>
          </TC.StyledParagraph>
        </SC.StretchedContainer>
        <FontAwesomeIcon
          onClick={() => closeModal()}
          icon={faXmark}
          size={EXTRA_LARGE}
          style={{ cursor: 'pointer' }}
        />
      </SC.HeaderContainer>

      <SC.BodyContainer>
        <p>
          This file must be a CSV and match{' '}
          <a href={pabmTemplateFile}>this template</a> exactly.
        </p>

        <CSVReader
          isPabmUpload={true}
          setDataToExport={setDataToExport}
          setAcceptedCsvFile={setAcceptedCsvFile}
          setAppendedCsvFile={setAppendedCsvFile}
        />

        <SC.ActionButtonWrapper>
          <TC.ActionButton
            disabled={!canExport || !appendedCsvFile || isLoading}
            backgroundColor={themeContext.colors.general.green}
            textColor={themeContext.colors.general.white}
            onClick={() => sendForProcessing()}
          >
            {isLoading ? 'Uploading...' : `Upload`}
          </TC.ActionButton>
        </SC.ActionButtonWrapper>
      </SC.BodyContainer>
    </SC.Wrapper>
  );
};

export default PabmModal;
