import { Interfaces } from '@configur-tech/discover-core-types';
import {
  COMPANY_DOMAIN_ALIAS,
  COMPANY_DOMAIN_FIELD,
  SEARCH_ID_FIELD_ALIAS,
  SEARCH_ID_FIELD_ID,
} from '../../consts/apiConsts';
import HttpHelper from '../../utils/http-helper/HttpHelper';

const endpoint = process.env['REACT_APP_CRUD_API'] || '';
const searchedCompaniesEndpoint =
  process.env['REACT_APP_USER_SEARCH_COMPANY'] || '';
const scoreEndpoint = process.env['REACT_APP_SCORE_COMPANY'] || '';
const scoredCompaniesEndpoint = process.env['REACT_APP_SCORED_COMPANIES'] || '';

export interface FilterQuery {
  operator: string;
  field: string;
  alias: string;
  datasetMetaId: string;
  value: {
    type: string;
    value: string;
  };
}

type CompanyInputs = {
  body: {
    domain: string;
    companyName?: string;
    companyDescription?: string;
    tags?: string;
    boosters: string;
  };
  user: Interfaces.User;
  boosters: Interfaces.Boosters[];
  searchId: string;
};

export interface ScoreApiResponse {
  statusCode: number;
  message: string;
  data: {
    successfulRowIds: number;
    failedRowIds: number;
    totalResponse: number[];
  };
}

export interface User {
  data: {
    entries: {
      row_id?: number;
      auth0Id?: string;
      firstName: string;
      lastName: string;
      avatar: string;
      organisation: string;
      savedCompanies: string;
    }[];
  };
}

// TODO: Pull from core-types when available
export interface EntriesResponse {
  statusCode: number;
  message: string;
  data: {
    entries: Interfaces.Company[];
    next: {
      hasMoreEntries: boolean;
      totalCount: number;
    };
  };
}

export interface EntryResponse {
  data: {
    entry: Interfaces.Company[];
  };
}

export interface ConfigurReadCompaniesData {
  entries: Interfaces.Company[];
  next: {
    hasMoreEntries: boolean;
    totalCount: number;
  };
}

export interface FollowUnfollowResponse {
  statusCode: number;
  message: string;
  data: {
    success: {
      type: string;
      message: string;
    };
  };
}

export default class CompanyService {
  public static async getCompanies(
    token: string,
    filterQuery?: string,
    encoded?: boolean,
    limit?: number,
    pageNum?: number,
    noOrdering?: boolean,
  ): Promise<EntriesResponse> {
    const ah = new HttpHelper(token);

    const orderByString = 'orderBy=-overallScore&';

    const constructUrl = (): string => {
      if (filterQuery && !encoded) {
        return `${scoredCompaniesEndpoint}?${
          !noOrdering ? orderByString : ''
        }${filterQuery}`;
      }

      if (filterQuery && encoded && !limit && pageNum) {
        const encodedQuery = encodeURIComponent(filterQuery);
        return `${scoredCompaniesEndpoint}?${
          !noOrdering ? orderByString : ''
        }conditions=${encodedQuery}`;
      }

      if (filterQuery && encoded && limit) {
        const encodedQuery = encodeURIComponent(filterQuery);
        return `${scoredCompaniesEndpoint}?${
          !noOrdering ? orderByString : ''
        }limit=${limit}&conditions=${encodedQuery}`;
      }

      if (filterQuery && encoded) {
        const encodedQuery = encodeURIComponent(filterQuery);
        return `${scoredCompaniesEndpoint}?${
          !noOrdering ? orderByString : ''
        }conditions=${encodedQuery}`;
      }

      return scoredCompaniesEndpoint;
    };

    return await ah.get<EntriesResponse>(constructUrl(), undefined, {
      'X-API-Key': process.env['REACT_APP_SCORED_COMPANIES_X_API_KEY'] || '',
    });
  }

  public static async getRecentCompanies(
    token: string,
  ): Promise<ConfigurReadCompaniesData> {
    const ah = new HttpHelper(token);

    const filterQuery = [
      {
        operator: '=',
        field: SEARCH_ID_FIELD_ID,
        alias: SEARCH_ID_FIELD_ALIAS,
        datasetMetaId: process.env.REACT_APP_COMPANIES_DATASET_META,
        value: { type: 'CONSTANT', value: '0' },
      },
    ];

    const stringified = JSON.stringify(filterQuery);
    const encoded = encodeURIComponent(stringified);
    const response = await ah.get<EntriesResponse>(
      `${scoredCompaniesEndpoint}?limit=50&pageNum=1&orderBy=-row_id&conditions=${encoded}`,
      undefined,
      {
        'X-API-Key': process.env['REACT_APP_SCORED_COMPANIES_X_API_KEY'] || '',
      },
    );

    // Get most recent companies that have been added to the DB
    const recent = response.data.entries.filter((ent) => ent.searchId === '0');

    return {
      entries: recent,
      next: {
        ...response.data.next,
      },
    };
  }

  public static async getSubscribedCompanies(
    token: string,
    companies: string[],
  ): Promise<Interfaces.Company[]> {
    try {
      const ah = new HttpHelper(token);

      const domains = companies.map((com) => com.replace(/^/, 'www.'));

      const filterPayload = [
        {
          operator: 'AND',
          value: [
            {
              operator: 'IN',
              field: COMPANY_DOMAIN_FIELD,
              alias: COMPANY_DOMAIN_ALIAS,
              datasetMetaId: process.env.REACT_APP_COMPANIES_DATASET_META,
              value: {
                type: 'CONSTANT',
                value: domains,
              },
            },
            {
              operator: '=',
              field: SEARCH_ID_FIELD_ID,
              alias: SEARCH_ID_FIELD_ALIAS,
              datasetMetaId: process.env.REACT_APP_COMPANIES_DATASET_META,
              value: { type: 'CONSTANT', value: '0' },
            },
          ],
        },
      ];

      const stringified = JSON.stringify(filterPayload);
      const encoded = encodeURIComponent(stringified);
      const companyData = await ah.get<EntriesResponse>(
        `${scoredCompaniesEndpoint}?limit=50&pageNum=1&conditions=${encoded}`,
        undefined,
        {
          'X-API-Key':
            process.env['REACT_APP_SCORED_COMPANIES_X_API_KEY'] || '',
        },
      );

      return companyData.data.entries;
    } catch (error) {
      const err = error as string;
      throw new Error(err);
    }
  }

  public static async getSearches(
    token: string,
    queryParams?: FilterQuery[],
    encoded?: boolean,
  ): Promise<EntriesResponse> {
    const ah = new HttpHelper(token);

    const encodedQuery = encoded
      ? encodeURIComponent(JSON.stringify(queryParams))
      : queryParams;

    const url = queryParams
      ? `${searchedCompaniesEndpoint}?conditions=${encodedQuery}`
      : searchedCompaniesEndpoint;

    return await ah.get(url, undefined, {
      'X-API-Key':
        process.env['REACT_APP_USER_SEARCH_COMPANIES_X_API_KEY'] || '',
    });
  }

  public static async postSearch(
    token: string,
    searchData,
  ): Promise<EntriesResponse> {
    const ah = new HttpHelper(token);

    return await ah.post(searchedCompaniesEndpoint, searchData, {
      'X-API-Key':
        process.env['REACT_APP_USER_SEARCH_COMPANIES_X_API_KEY'] || '',
    });
  }

  public static async putSearch(
    token: string,
    searchData,
  ): Promise<EntryResponse> {
    const ah = new HttpHelper(token);

    return await ah.put(
      `${searchedCompaniesEndpoint}/${searchData.row_id}`,
      { updateData: searchData },
      {
        'X-API-Key':
          process.env['REACT_APP_USER_SEARCH_COMPANIES_X_API_KEY'] || '',
      },
    );
  }

  public static async scoreCompanies(
    token: string,
    companyData: CompanyInputs,
  ): Promise<ScoreApiResponse> {
    const ah = new HttpHelper(token);
    return await ah.post<ScoreApiResponse>(scoreEndpoint, companyData, {});
  }

  public static async followCompany(
    token: string,
    domain: string,
  ): Promise<FollowUnfollowResponse> {
    const ah = new HttpHelper(token);
    return await ah.get<FollowUnfollowResponse>(
      `${endpoint}/followDomain/${domain}`,
      {},
    );
  }

  public static async unfollowCompany(
    token: string,
    domain: string,
  ): Promise<FollowUnfollowResponse> {
    const ah = new HttpHelper(token);
    return await ah.get<FollowUnfollowResponse>(
      `${endpoint}/unfollowDomain/${domain}`,
      {},
    );
  }
}
