import { Interfaces } from '@configur-tech/discover-core-types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import EventsService from '../../../services/predict-leads/EventsService';
import { AppThunk } from '../../store';

type EventData = Interfaces.PLEvent[];

export interface EventsState {
  data: EventData;
  loading: boolean;
  error: string | null;
}

const initialState: EventsState = {
  data: [],
  loading: false,
  error: null,
};

const EventsSlice = createSlice({
  name: 'events',
  initialState,
  reducers: {
    // Fetch events
    fetchEventsStart(state) {
      state.loading = true;
      state.error = null;
    },
    fetchEventsSuccess(state, action: PayloadAction<Interfaces.PLEvent[]>) {
      state.data = action.payload;
      state.loading = false;
      state.error = null;
    },
    fetchEventsFailure(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { fetchEventsStart, fetchEventsSuccess, fetchEventsFailure } =
  EventsSlice.actions;

export default EventsSlice.reducer;

export const fetchEvents =
  (token: string, domain): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(fetchEventsStart());
      const fetched = await EventsService.getEvents(token, domain);
      dispatch(fetchEventsSuccess(fetched as unknown as Interfaces.PLEvent[]));
    } catch (error) {
      const err = error as string;
      dispatch(fetchEventsFailure(err.toString()));
    }
  };
